.modal{
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

}

.modal .content{
    transition: 0.5s;
    transform: scale(0.5);
}

.modal.active{
    opacity: 1;
    pointer-events: all;
}

.content {
    color: #ffffff;
    right: 0;
    top: -20%;
    left: 0;
    padding: 1.5rem;
    z-index: 9999;
    border: 1px solid rgba(0, 0, 0, 0.192);
    transition: 0.2s ease-in;
    border-radius: 5px;
    transform: scale(0.5);

}

.content.active{
    transform: scale(1);
}

.close{
    color: #c2c2c2;
    position: absolute;
    font-size: 40px;
    right: 25%;
    top: 0;
    cursor: pointer;
}

.close:hover{
    color: white;
    transition: 0.2s;
}