.modal-content {
    width: 400px;
    position: relative;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.192);
    padding: 2rem;
    border-radius: 5px;
    background-color: #ffffff5b;
    color: #ffffff;
}

.modal-content h1{
    text-align: center;
}

.modal-content input {
    margin-bottom: 1rem;
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ffffff;
}

.submit-btn{

    border-color: #6487df;
    color: #fff;
    box-shadow: 0 0 40px 40px #6487df inset, 0 0 0 0 #6487df;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}

.modal-content .submit-btn {
    text-align: center;
    border: none;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 1rem;
}

.submit-btn:hover{
    box-shadow: 0 0 10px 0 #6487df inset, 0 0 10px 4px #6487df;
    color: #ffffffff;
}


.invalid-field{
    background-color: #c00000;
    border: 5px solid #c00000;
    border-radius: 40px;
    text-align: center;

}

.category-input{
    border-bottom: 1px solid #ffffff;
    border-radius: 20px;
    background-color: #00000000;
}