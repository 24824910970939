*,
*::before,
*::after {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul[class],
ol[class] {
  list-style: none;
}

.btn:hover, .btn:focus {
  color: #ffffff;
  outline: 0;
}

.container{
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #667db6; 
  background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);  
  background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6); 
}