.note-table {
    border-collapse: collapse;
    margin: 25px 0;
    min-width: 400px;
    font-size: 1.5em;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
}

.note-table th {
    padding: 12px 25px;
}

.note-table tr{
    text-align: left;
    font-weight: bold;
    color: #ffff;
    margin-bottom: 15px;
    overflow: hidden;

}