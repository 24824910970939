.note-title {
    display: flex;
    align-items: center;

}

td .icon-title {
    padding: 10px;
    margin: 10px;
    color: #ffffff;
    background-color: #ffffff24;
    border-radius: 100px;
}

.text-ellipsis {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.icon {
    padding: 10px;
    color: #000000;
}
.icon:hover {
    padding: 10px;
    color: #676666;
}

.icon:active {
    padding: 10px;
    color: #adadad;
}

.icon i{
    cursor: pointer;
}

.note-table tbody tr {
    background-color: #f3f3f37b;
    color: rgb(0, 0, 0);
    text-overflow: ellipsis;

}
.note-table tr {
    margin-bottom: 15px;
    overflow: hidden;
}

.note-table td, .note-table th {
    padding: 12px 25px;
}